<template>
  <div class="login_page">
    <div class="left_part">
      <div class="copyright">
        Copyright Drake Business Logistics © 2020-{{currentYear}}
      </div>
    </div>
    <div class="right_part">
      
      <div class="welcome">
        <h1>Welcome to Digital Hub</h1>
        <h2 class="sp">Sign in to your account</h2>
      </div> 
      <div class="version">
        <span>Version: {{$appVersion}}</span> 
      </div> 
      
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <!-- Custom Alert HTML -->
        <!-- <div class="custom-alert">
          <span class="alert-message">Due to a technical issue the email alerts are not generating at present. We are working towards a resolution soonest. In the interim please contact the respective mail room for alerts or tracking information.</span>
        </div> -->
        <div class="login_form_wrapper">
          <div class="form_label_wrapper">Your email address</div>
          <ValidationProvider name="email" rules="required|email" v-slot="{ errors }" slim>
            <div class="form_element_wrapper">
              <input
                type="text"
                v-model="user.email"
                placeholder="Enter email here"
                @keyup.enter="$refs.password.focus()"
                :class="errors[0] && show_error ? 'warning-border':''"
              />
              <p class="error-class" v-if="show_error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
          <div class="form_label_wrapper">Password</div>
          <ValidationProvider name="password" rules="required" v-slot="{ errors }" slim>
            <div class="form_element_wrapper">
              <input
                type="password"
                v-model="user.password"
                placeholder="Enter password here"
                ref="password"
                :class="errors[0] && show_error ? 'warning-border':''"
                @keyup.enter="$refs.submit.click()"
              />
              <p class="error-class" v-if="show_error">{{ errors[0] }}</p>
            </div>
          </ValidationProvider>
          <div class="form_foorter_wrapper">
            <!-- <label>
            <input type="checkbox" />
            <span>Remember me</span>
            </label>-->
          </div>
          <div class="form_element_wrapper">
            <button
              @click="submit"
              ref="submit"
              :disabled="submit_btn.disabled"
            >{{submit_btn.value}}</button>
          </div>
          <div v-if="SSOLoginEnabled" class="form_element_wrapper">
            <button
              style="background: #2fa1dd; color: white;"
              @click="redirectLogin"
            >{{microsoft_btn.value}}</button>
          </div>
        </div>
      </ValidationObserver>
    </div>

    <!-- Reset Password Popup -->
    <div v-if="showResetPasswordPopup" class="modal-overlay">
      <div class="modal">
          <h2>Reset Your Password</h2>
          <p>Your password has expired. Please set a new one.</p>
          <br> <!-- Line space -->

          <!-- Email (Pre-filled and Disabled) -->
          <div class="form_element_wrapper">
              <input type="text" v-model="user.email" disabled />
          </div>

          <!-- New Password Field -->
          <div class="form_element_wrapper">
              <input type="password" v-model="newPassword" placeholder="Enter new password"  />
          </div>

          <!-- Confirm Password Field -->
          <div class="form_element_wrapper">
              <input type="password" v-model="confirmPassword" placeholder="Confirm new password" />
              <small v-if="confirmPassword && newPassword !== confirmPassword" class="invalid">
                  Passwords do not match.
              </small>
          </div>

          <br> <!-- Line space -->

          <!-- Password Policy Validation Messages -->
          <div class="password-policy">
              <small :class="{ 'valid': isPasswordLengthValid, 'invalid': !isPasswordLengthValid }">
                  Password must contain 8 to 12 characters.
              </small>
              <small :class="{ 'valid': hasUpperCase, 'invalid': !hasUpperCase }">
                  Must contain an uppercase letter.
              </small>
              <small :class="{ 'valid': hasLowerCase, 'invalid': !hasLowerCase }">
                  Must contain a lowercase letter.
              </small>
              <small :class="{ 'valid': hasNumber, 'invalid': !hasNumber }">
                  Must contain a number.
              </small>
              <small :class="{ 'valid': hasSpecialCharacter, 'invalid': !hasSpecialCharacter }">
                  Must contain a special character.
              </small>
          </div>

          <br> <!-- Line space -->

          <!-- Buttons -->
          <div class="form_element_wrapper">
              <button @click="changePassword">Reset Password</button>
              <button @click="cancelPasswordChange" class="cancel-btn">Cancel</button>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ConfigurationEnum } from "@/enums";
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "login",
  computed: {
      ...mapGetters({
          configurations: 'configuration/configurations',
      }),

      isPasswordLengthValid() {
            const allowedCharacters = /^[A-Za-z\d@$!%*?&#]+$/;
            return (
                this.newPassword.length >= 8 &&
                this.newPassword.length <= 12 &&
                allowedCharacters.test(this.newPassword)
            );
        },
        hasUpperCase() {
            return /[A-Z]/.test(this.newPassword);
        },
        hasLowerCase() {
            return /[a-z]/.test(this.newPassword);
        },
        hasNumber() {
            return /[0-9]/.test(this.newPassword);
        },
        hasSpecialCharacter() {
            return /[@$!%*?&#]/.test(this.newPassword);
        },
        isPasswordValid() {
            return (
                this.isPasswordLengthValid &&
                this.hasUpperCase &&
                this.hasLowerCase &&
                this.hasNumber &&
                this.hasSpecialCharacter &&
                this.newPassword === this.confirmPassword
            );
        }
  },
  async created() {
    await this.setConfigurations();
    localStorage.clear();
    if (window.checkIdleSession) {
      clearInterval(window.checkIdleSession);
    }
    if (window.currentClientTime) {
      clearInterval(window.currentClientTime);
    }

    if (this.$route.query.error) {
      this.toast.error(this.$route.query.error);
      this.$router.replace('/')
    }

    // this.SSOLoginEnabled = this.configurations[ConfigurationEnum.SSO_LOGIN];
    this.booking_id = this.$route.query.booking_id;
    this.path = this.$route.query.path;

    if(this.booking_id && this.path)  {
      localStorage.redirect_booking_id = this.booking_id;
      localStorage.redirect_path = this.path;
    }
  },
  data() {
    return {
      user: {
        email: "",
        password: ""
      },
      submit_btn: {
        disabled: false,
        value: "SIGN IN"
      },
      microsoft_btn: {
        disabled: false,
        value: "CONTINUE WITH KWM ACCOUNT"
      },
      show_error: false,
      currentYear: new Date().getFullYear(),
      SSOLoginEnabled: 1,
      booking_id: '',
      path: '',
      newPassword: "", // Store new password
      confirmPassword: "", // Confirm new password
      showResetPasswordPopup: false, // Show popup when password expires
    };
  },
  methods: {
    ...mapActions({
			setConfigurations: 'configuration/setConfigurations',
		}),
    redirectLogin () {
      window.location= process.env.VUE_APP_API_URL + "/auth/azure/redirect";
    },
     async submit() {
      this.show_error = false;
      let validator = await this.$refs.observer.validate();
      if (!validator) {
        this.show_error = true;
      } else {
        this.submit_btn.disabled = true;
        this.submit_btn.value = "SIGNING IN...";
        this.axios
          .post("/api/auth/login", this.user)
          .then(response => {
            console.log(response);
            this.submit_btn.disabled = false;
            this.submit_btn.value = "SIGN IN";
            if (response.data.status_code == 2) {
              localStorage.token = response.data.token;
              this.toast.success("Logged in successfully");
              if(this.booking_id) {
                this.$router.replace(`/pages/${this.path}?booking_id=${this.booking_id}`);
              } else {
                this.$router.replace("/pages/dashboard");
              }
            } else if(response.data.status_code == 3 && response.data.password_expired) {
                this.showResetPasswordPopup = true;
                this.toast.error(response.data.msg);
            }else {
              localStorage.token = response.data.token;
              this.$router.push("/verifyotp");
            }
          })
          .catch(error => {
            this.submit_btn.disabled = false;
            this.submit_btn.value = "SIGN IN";
            console.log(error);
          });
      }
    },

    async changePassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.toast.error("Passwords do not match!");
        return;
      }
      try {
        const response = await this.axios.post("/api/auth/resetpassword", {
          email: this.user.email, // Include email in the request
          old_password: this.user.password,
          new_password: this.newPassword,
        });

        if (response.status === 200) {
          // Password updated successfully
          this.toast.success(response.data.msg);
          this.showResetPasswordPopup = false; 
          this.$router.go(this.$router.currentRoute);
        } 
      } catch (error) {
        console.error("Password update error:", error);
      }
    },
    cancelPasswordChange() {
    this.newPassword = "";
    this.confirmPassword = "";
    this.showResetPasswordPopup = false;
    this.$router.go(0); // Refresh the page
}

  }
};
</script>
<style scoped>


/* Password Validation Messages */
small {
    font-size: 12px;
    display: block;
    margin-top: 5px;
}

/* Green when valid */
.valid {
    color: green;
}

/* Red when invalid */
.invalid {
    color: red;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 450px;
    height: 540px;
    text-align: center;
}

.cancel-btn {
    background: #ccc;
    color: black;
    border: none;
}

.wrapper {
  width: 100%;
  height: 100%;
}
.login_page {
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
}
.left_part {
  width: 100%;
  height: 100%;
  background: #dce5ec url(../assets/login-bg-new.png) no-repeat;
  background-size: contain;
  min-height: 200px;
  background-position: center;
  position: relative;
}
.copyright{
  position: absolute;
  text-align: center;
  height:30px; padding:5px 0;
  width:100%;
  color:#004a7c;
  font-size: 14px;
  bottom:10px
}

.version{
  position: absolute;
  text-align: right;
  height: 30px;
  padding: 5px 0;
  width: 45%;
  color: #004a7c;
  font-size: 14px;
  bottom: 10px;
}

.right_part {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.right_part .logo_div {
  width: 175px;
  height: 90px;
  margin-bottom: 30px;
}
.right_part .logo_div img {
  display: block;
  width: 100%;
  height: auto;
}
.right_part h2.sp {
  display: block;
  margin-top: 0;
  font-size: 24px;
  margin-bottom: 20px;
  margin-left: 0;
  text-align: left;
}
.right_part .login_form_wrapper {
  width: 460px;
  height: auto;
  min-height: 100px;
  padding: 20px 0;
}
.right_part .welcome {
  width: 460px;
  height: auto;
  min-height: 100px;
  
}
.right_part .welcome h1{
  display: block;
  margin-top: 0;
  font-size: 32px;
  font-weight: bold;
  color:#2fa1dd;
  margin-left: 0;
}
.form_label_wrapper {
  height: auto;
  font-size: 18px;
  padding: 10px 30px 0 0;
  margin-bottom: 10px;
}
.form_element_wrapper {
  margin-bottom: 20px;
  height: 40px;
}
.form_foorter_wrapper {
  height: 30px;
  font-size: 18px;
  padding: 10px 30px 0 30px;
  margin-bottom: 20px;
}
label {
  display: block;
  float: left;
}
input[type="checkbox"] {
  display: inline-block;
  margin-right: 10px;
}
a.forgot_password {
  display: block;
  float: right;
  font-size: 14px;
  margin-right: 0;
  color: #000;
}
input[type="text"] {
  width: 100%;
  height: 50px;
  background: #dce5ec;
  border: 1px solid #dce5ec;
  font-size: 16px;
  padding: 5px 20px;
}
input[type="password"] {
  width: 100%;
  height: 50px;
  background: #dce5ec;
  border: 1px solid #dce5ec;
  font-size: 16px;
  padding: 5px 20px;
}
button {
  width: 100%;
  height: 50px;
  background: #fff;
  color: #2fa2db;
  font-size: 16px;
  padding: 5px 30px;
  border: 3px solid #2fa2db;
}

/* button:disabled {
  background: #dce5ec;
} */

/* button:hover { background: #1c7fb5; } */
/* button:disabled {
    background: #d3d3d3;
    border-color: #b0b0b0;
    color: #7a7a7a;
    cursor: not-allowed;
    opacity: 0.7;
} */
.custom-alert {
  width: 460px;
  text-align: justify;
  padding: 15px 20px;
  background-color: #f8d7da;
  color: #721c24; 
  border: 1px solid #f5c6cb; 
  border-radius: 4px; 
  margin-bottom: 20px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.custom-alert .alert-message {
  margin-right: 10px;
}

</style>